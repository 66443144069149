import * as React from "react";

import dynamic from "next/dynamic";

import { useTicketingV2IsActive } from "../../features/FormV2/hooks/useTicketingV2IsActive";

import { TicketingOutput } from "@/types/trpc";

const TicketingFormV2Page = dynamic(() =>
  import("@/src/pages/ticketingV2/TicketingFormV2Page").then((mod) => mod.TicketingFormV2Page)
);
const TicketingContainerV1 = dynamic(() => import("./TicketingContainerV1").then((mod) => mod.TicketingContainerV1));

type TicketingVersionSelectorProps = {
  isSmallSize?: boolean;
  isFullWidth?: boolean;
  hideHeader?: boolean;
  isEmbed?: boolean;
  ssrTicketing: TicketingOutput;
  forceV2?: boolean;
};

export const TicketingVersionSelector: React.FunctionComponent<
  React.PropsWithChildren<TicketingVersionSelectorProps>
> = ({ isSmallSize, isFullWidth, hideHeader, ssrTicketing, isEmbed }) => {
  const { ticketingV2IsActive, loading } = useTicketingV2IsActive(ssrTicketing);
  if (loading) {
    return null;
  }

  if (ticketingV2IsActive) {
    return <TicketingFormV2Page data={ssrTicketing} isEmbed={isEmbed} />;
  }

  return (
    <TicketingContainerV1
      ssrTicketing={ssrTicketing}
      isSmallSize={isSmallSize}
      isFullWidth={isFullWidth}
      hideHeader={hideHeader}
      isEmbed={isEmbed}
    />
  );
};
