/* eslint-disable no-console */
import { FormType, FormErrors, FrontendRoutes } from "@simplyk/common";
import { v4 } from "uuid";

import { AppFrontendProvider } from "../../components/AppFrontendProvider/AppFrontendProvider";
import { TicketingHead } from "../../components/Head/TicketingHead";
import { FrontendAuctionProvider } from "../../contexts/FrontendAuctionContext";
import { UserInputProvider } from "../../contexts/UserInputContext";
import { Locales, OrganizationObject } from "../../gql/gql-types";
import { TicketingFrontendProvider } from "../../providers/FrontendTicketingProvider";

import { TicketingVersionSelector } from "@/components/TicketingContainer/TicketingVersionSelector";
import { getSSRTicketing, getTranslation, provideOrganization } from "@/helpers/getStaticProps";
import { usePageScrollUp } from "@/hooks/usePageScrollUp";
import { useTheme } from "@/hooks/useTheme";
import { ServerSideContext } from "@/types/app";
import { TicketingOutput } from "@/types/trpc";

export interface TicketingPageProps {
  ticketing: TicketingOutput;
}

const TicketingPage = ({ ticketing }: TicketingPageProps) => {
  usePageScrollUp();
  const theme = useTheme(ticketing.color);

  return (
    <>
      {/* Nothing should wrap or block the Head component */}
      <TicketingHead ticketing={ticketing} country={ticketing.organization?.country} />
      <AppFrontendProvider
        theme={theme}
        organization={ticketing.organization as OrganizationObject}
        formType={FormType.Ticketing}
        formData={ticketing}
        fields={ticketing.ticketingFields}
        isEmbed={false}
        shouldDisplayLogin={false}
        isFundraiser={false}
        themeColor={ticketing.color}
      >
        <TicketingFrontendProvider ticketing={ticketing}>
          <FrontendAuctionProvider ticketing={ticketing}>
            <UserInputProvider>
              <TicketingVersionSelector ssrTicketing={ticketing} />
            </UserInputProvider>
          </FrontendAuctionProvider>
        </TicketingFrontendProvider>
      </AppFrontendProvider>
    </>
  );
};

export const getServerSideProps = async (context: ServerSideContext<{ ticketingId: string }>) => {
  const requestId = (context.req?.headers?.["x-request-id"] as string) || v4();
  const { ticketingId } = context.params;

  const locale = context.locale.toUpperCase() as Locales;

  try {
    const [ticketingResponse, translations] = await Promise.all([
      getSSRTicketing({ ticketingIdOrUrlPath: ticketingId, requestId }),
      getTranslation(context.locale),
    ]);

    if (ticketingResponse.error) {
      const code = ticketingResponse.error.code;
      if (code === FormErrors.FormDeleted) {
        console.log(`Form deleted - redirecting to Gonne ticketingId=${ticketingId} ssrId=${requestId}`);
        return { redirect: { destination: FrontendRoutes.Gone } };
      }

      console.log(`NEXT_ERROR: form not found - redirecting to notFound ticketingId=${ticketingId} ssrId=${requestId}`);
      return { redirect: { destination: FrontendRoutes.NotFound } };
    }

    return {
      props: {
        ...translations,
        ticketing: ticketingResponse.data.organization
          ? ticketingResponse.data
          : provideOrganization(ticketingResponse.data, locale),
      },
    };
  } catch (error) {
    console.log(
      `NEXT_ERROR: request failed - redirecting to notFound ticketingId=${ticketingId} ssrId=${requestId}`,
      error
    );
    return { redirect: { destination: FrontendRoutes.NotFound } };
  }
};

export default TicketingPage;
