import { useContext } from "react";

import { AmplitudeFlagKey, convertTimestampToDateNumber } from "@simplyk/common";

import { useCurrentUserContext } from "../../../contexts/CurrentUserContext";
import { FrontendTicketingContext } from "../../../contexts/FrontendTicketingContext";
import { TicketingFormCategory } from "../../../gql/gql-types";
import { useAmplitudeFeatureFlag } from "../../../hooks/amplitude/useAmplitudeFeatureFlag";

import { TICKETING_V2_CATEGORIES } from "@/types/ticketing";
import { TicketingOutput } from "@/types/trpc";

export const useTicketingV2IsActive = (ticketing: TicketingOutput) => {
  const { isCampaignRegistration } = useContext(FrontendTicketingContext);

  const organizationCreatedAtDate = ticketing?.organization?.createdAtUtc
    ? convertTimestampToDateNumber(ticketing.organization.createdAtUtc)
    : "undefined";

  const isAuction = ticketing.formCategory === TicketingFormCategory.Auction;

  const { organization: userOrg } = useCurrentUserContext();
  const formOrg = ticketing.organization;
  const isOwner = Boolean(formOrg && userOrg && formOrg?.id === userOrg.id);

  const { isActive: auctionV2IsActive, loading: auctionLoading } = useAmplitudeFeatureFlag({
    flagKey: AmplitudeFlagKey.AuctionMemorableForm,
    userProperties: {
      donor_organization_country: ticketing.organization!.country!,
      donor_organization_creation_date: organizationCreatedAtDate,
    },
    skip: ticketing.formCategory !== TicketingFormCategory.Auction || isOwner,
  });

  if (isAuction) {
    return {
      ticketingV2IsActive: !isOwner && auctionV2IsActive,
      loading: auctionLoading,
    };
  }

  return {
    ticketingV2IsActive: TICKETING_V2_CATEGORIES.includes(ticketing.formCategory) && !isCampaignRegistration,
    loading: false,
  };
};
