import { CampaignAction } from "@simplyk/common";

import { TeamData } from "../components/CampaignModal/teamData";
import { ManualCommandAdditionalInfoForm } from "../components/ManualCommandDrawer/ManualCommandAdditionalInfo/ManualCommandAdditionalInfo";
import { ManualCommandGeneralInfoForm } from "../components/ManualCommandDrawer/ManualCommandGeneralInfo/ManualCommandGeneralInfo";
import { TicketingFormCategory } from "../gql/gql-types";

import { CustomAnswer } from "./customQuestion";

export interface FullRateError {
  id: string;
  remainingTickets: number;
}

export interface ParticipantAnswers {
  rateId: string;
  answers: CustomAnswer[];
}

export interface TicketPurchase {
  rateId: string;
  purchasedNumber?: number;
  attendeesByTicket: number;
  automaticRenewal?: boolean;
  annotation?: string;
  bid?: number;
}

export type CreateTeamData = TeamData & {
  teamName: string;
  createIndividualFundraising: boolean;
};

export interface TicketingFundraisingData {
  type: CampaignAction;
  inputs: TeamData[];
  createTeam: CreateTeamData;
}

export interface TicketingPaymentInput extends ManualCommandGeneralInfoForm, ManualCommandAdditionalInfoForm {
  ticketsPurchased?: TicketPurchase[];
  customAnswersOfOrder: CustomAnswer[] | undefined;
  customAnswersOfParticipants: ParticipantAnswers[] | undefined;
  discountId?: string;
  tip: number;
  firstParticipantIsBuyer?: boolean;
  extraDonation?: number | null;
  occurrenceId?: string;
  fundraising?: TicketingFundraisingData | undefined;
}

export const TICKETING_V2_CATEGORIES = [
  TicketingFormCategory.Event,
  TicketingFormCategory.Shop,
  TicketingFormCategory.MembershipV2,
  TicketingFormCategory.Membership,
  TicketingFormCategory.Lottery,
  TicketingFormCategory.Custom,
];
